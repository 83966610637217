import SettingsIcon from '@mui/icons-material/Settings';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const icons = {
	settings: SettingsIcon,
	general: SettingsSuggestIcon,
	permission: LockPersonIcon,
	database: StorageIcon,
	account: AccountCircleIcon,
};

const Data = {
	type: 'group',
	title: 'ตั้งค่า',
	icon: icons.settings,
	children: [
		{
			type: 'item',
			title: 'ตั้งค่าระบบ',
			icon: icons.general,
			url: '/settings/general',
			level: 80,
		},
		{
			type: 'item',
			title: 'สิทธิ์การเข้าถึง',
			icon: icons.permission,
			url: '/settings/permission',
			level: 80,
			visible: false,
		},
		{
			type: 'item',
			title: 'ฐานข้อมูล',
			icon: icons.database,
			url: '/settings/database',
			level: 80,
			visible: false,
		},
		{
			type: 'item',
			title: 'บัญชีผู้ใช้งาน',
			icon: icons.account,
			url: '/settings/account',
		},
	],
};

export default Data;
