import { useMemo } from 'react';

import { DesktopMenu, MobileMenu } from 'menu';

const filterMenuItems = (role, menu) => {
	menu.forEach((item, index) => {
		if (role.level < 90) {
			if (item.roles && !item.roles.includes(role.id)) {
				item.visible = false;
			}

			if (item.level && role.level < item.level) {
				item.visible = false;
			}
		}

		if (item.visible === false) {
			delete menu[index];
		}

		if (item.children) {
			filterMenuItems(role, item.children);
		}
	});
};

function useMenu(name = '', user) {
	const items = useMemo(() => {
		if (user && user.role) {
			const menu =
				name?.toLowerCase() === 'mobile' ? MobileMenu : DesktopMenu;
			const _items = menu.map((item) => ({ ...item }));
			filterMenuItems(user.role, _items);
			return _items;
		}

		return [];
	}, [name, user]);

	return { name, items };
}

export default useMenu;
