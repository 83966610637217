import dayjs from 'dayjs';
import thTH from 'dayjs/locale/th';
import updateLocale from 'dayjs/plugin/updateLocale';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(updateLocale);

dayjs.extend(buddhistEra);

dayjs.locale('th');

dayjs.updateLocale('th', {
	weekStart: 1,
	formats: Object.assign({}, thTH.formats, {
		L: 'DD/MM/BBBB',
		LL: 'D MMMM BBBB',
		LLL: 'D MMMM BBBB เวลา H:mm',
		LLLL: 'วันddddที่ D MMMM BBBB เวลา H:mm',
	}),
});

export default class DayjsAdapter extends AdapterDayjs {
	constructor({ locale, formats, instance }) {
		super({ locale, formats, instance });

		Object.assign(this.formatTokenMap, {
			BB: 'year',
			BBBB: { sectionType: 'year', contentType: 'digit', maxLength: 4 },
		});
	}
}
