import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DiscountIcon from '@mui/icons-material/Discount';

import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const icons = {
	home: HomeIcon,
	dashboard: DashboardIcon,
	update: PublishedWithChangesIcon,
	bank: AssuredWorkloadIcon,
	discount: DiscountIcon,
};

const dashboard = {
	type: 'group',
	title: 'หน้าหลัก',
	icon: icons.home,

	children: [
		{
			type: 'item',
			title: 'ภาพรวม',
			icon: icons.dashboard,
			url: '/',
		},
		{
			type: 'collapse',
			title: 'รายการรออนุมัติ',
			icon: icons.update,
			url: '/dashboard/approvals',
			level: 80,
			children: [
				{
					type: 'item',
					title: 'ธุรกรรม',
					icon: icons.bank,
					url: '/dashboard/approvals/transactions',
				},
				{
					type: 'item',
					title: 'ส่วนลด',
					icon: icons.discount,
					url: '/dashboard/approvals/orders',
				},
			],
		},
	],
};

export default dashboard;
