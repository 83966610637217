/**
 * External dependencies
 */

import { useRoutes } from 'react-router-dom';

/**
 * Internal dependencies
 */
import Auth from './Auth';
import CustomerService from './CustomerService';
import Dashboard from './Dashboard';
import Sales from './Sales';
import Expenses from './Expenses';
import Data from './Data';
import Report from './Report';
import Settings from './Settings';
import Error from './Error';

export default function Routes() {
	return useRoutes([
		Auth,
		CustomerService,
		Dashboard,
		Sales,
		Expenses,
		Data,
		Report,
		Settings,
		Error,
	]);
}
